<template>
  <tr class="jobs-list-row" @mouseenter="showAction = true" @mouseleave="showAction = false">
    <td class="text-left text-caption" v-text="item.id" />
    <td class="text-left text-caption">
      <div v-text="operationLocalized" />
      <div class="jobs-list-row__params" v-text="operationParameters" />
    </td>
    <td class="text-left text-caption" v-text="item.author.name || item.author.username" />
    <td class="text-left text-caption" v-text="dateTimeCreated" />
    <td class="text-left text-caption" style="max-width: 100px">
      <span v-if="item.finished">
        {{ dateTimeFinished }}
      </span>
    </td>
    <td class="text-caption">
      <progress-bar :total="item.total" :processed="item.processed" v-if="item.status === 'working'" />
      <span v-else>
        {{ item.status_localized }}
      </span>
    </td>
    <td class="text-caption">
      <div class="jobs-list-row__proceeded">
        <div>
          {{ processedPercent }}
        </div>
        <div class="jobs-list-row__action">
          <div class="jobs-list-row__action--btn">
            <AppButton theme="primary-light" text="Повторить" @click.native="repeat" v-show="showAction && showRerunButton" />
            <AppButton theme="danger-light" text="Отменить" @click.native="stop" v-show="showAction && showStopButton" />
          </div>
          <div class="jobs-list-row__action--link">
            <router-link class="text-caption jobs-list-row__action--link-erros" :to="`/operations/${item.id}/log`" v-if="showRerunButton && hasErrors">
              <span v-html="logText" />
              <v-icon size="14" color="primary">
                mdi-arrow-right
              </v-icon>
            </router-link>
            <span class="text-caption jobs-list-row__action--link-noerrors" v-html="logText" v-else-if="showAction && showRerunButton && !hasErrors" />
          </div>
        </div>
      </div>
    </td>
  </tr>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { OPERATIONS, RERUN_STATUSES, STOP_STATUSES } from '@/helpers/operations';

import AppButton from '@/components/AppButton';
import ProgressBar from '@/components/common/ProgressBar';

const TIME_OPTIONS = {
  hour: 'numeric',
  minute: 'numeric',
};
const DATE_OPTIONS = {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
};

export default {
  components: {
    AppButton,
    ProgressBar,
  },
  props: {
    item: { type: Object, required: true },
  },
  data() {
    return {
      showAction: false,
    };
  },
  computed: {
    ...mapGetters('reference', ['getRegionNameBySlug', 'getOwnerNameBySlug']),
    operationLocalized() {
      return OPERATIONS[this.item.operation_slug] ?? 'Неизвестно';
    },
    operationParameters() {
      const params = [];
      const extraParameterRegion = this.item.extra_parameters?.region_slug;
      const extraParameterOwner = this.item.extra_parameters?.owner_slug;
      if (extraParameterRegion) params.push(`Регион: ${this.getRegionNameBySlug(extraParameterRegion)}`);
      if (extraParameterOwner) params.push(`Источник: ${this.getOwnerNameBySlug(extraParameterOwner)}`);
      return params.join(', ');
    },
    dateTimeCreated() {
      if (!this.item.created) return '–';
      const date = new Date(Date.parse(this.item.created));

      return `${date.toLocaleString('ru', TIME_OPTIONS)} ${date.toLocaleString('ru', DATE_OPTIONS)}`;
    },
    dateTimeFinished() {
      if (!this.item.finished) return '—';
      const date = new Date(Date.parse(this.item.finished));

      return `${date.toLocaleString('ru', TIME_OPTIONS)} ${date.toLocaleString('ru', DATE_OPTIONS)}`;
    },
    processedPercent() {
      return this.item.total ? `${Math.floor((this.item.processed / this.item.total) * 100) || 0}%` : '0%';
    },
    showRerunButton() {
      return RERUN_STATUSES.includes(this.item.status);
    },
    showStopButton() {
      return STOP_STATUSES.includes(this.item.status);
    },
    hasErrors() {
      return this.item.errors > 0;
    },
    logText() {
      return this.hasErrors ? `Ошибок:&nbsp${this.item.errors}` : 'Ошибок&nbspнет';
    },
  },
  methods: {
    ...mapActions('operations', ['CREATE_JOB', 'STOP_JOB']),
    repeat() {
      const jobObject = {
        operation_slug: this.item.operation_slug,
        data_url: this.item.data_url,
      };

      if (this.item.extra_parameters) jobObject.extra_parameters = this.item.extra_parameters;

      this.$emit('repeat', jobObject);
    },
    async stop() {
      if (await this.$root.$confirm('Остановка выполнения', 'Вы действительно хотите остановить выполнение задачи?')) {
        try {
          this.STOP_JOB(this.item.id);
        } catch (e) {
          if (!e.isAxiosError) throw e;
          this.$notify.showErrorMessage(e.response);
        }
      }
    },
    brandInput({ val, isNew }) {
      this.canonicalBrandName = val;
      this.newBrand = isNew;
    },
  },
};
</script>

<style lang="scss" scoped>
.jobs-list-row {
  background-color: hsla(0, 0%, 98%, 1);

  &__lighted {
    color: hsla(0, 0%, 0%, 0.4);
  }

  &__proceeded {
    display: grid;
    grid-template-columns: 1fr 3fr;
    align-items: center;
  }

  &__action {
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: wrap;

    &--btn {
      flex: 1 0 auto;
      width: 80px;
      height: 35px;
      margin: 5px auto;
    }

    &--link {
      flex: 1 0 auto;
      padding-left: 10px;
    }

    &--link-erros {
      display: flex;
      align-content: baseline;
      text-decoration: none;
    }

    &--link-noerrors {
      text-decoration: none;
      color: hsla(0, 0%, 0%, 0.48);
    }
  }

  &__params{
    color: hsla(0, 0%, 0%, 0.4);
  }
}
</style>
