<template>
  <div class="upload">
    <span class="upload__label">
      {{ label }}
    </span>
    <div class="upload__filename" v-if="fileName">
      <span class="mr-2">
        {{ fileName || value }}
      </span>
      <v-icon title="удалить файл" class="upload__filename--icon" small @click="reset" v-if="file || value">
        mdi-delete-outline
      </v-icon>
    </div>
    <div class="upload__filename" v-if="value">
      <a style="text-decoration: none" :href="value" download>
        <v-icon title="cкачать файл" class="upload__filename--icon" small>
          mdi-download
        </v-icon>
      </a>
    </div>
    <label class="upload__upload-text" v-if="!fileName && !value">
      <input
        type="file"
        id="file"
        ref="fileInput"
        style="position: absolute; top: 0; left: 0; z-index: -1; opacity: 0; width: 40px;"
        :accept="accept"
        @change="handleFiles"
      >
      <span class="upload__link">
        загрузить файл
      </span>
    </label>
  </div>
</template>

<script>
import { upload } from '@/helpers/uploadFile';

const BASE_URL = 'https://245611.selcdn.ru/inventory/media/';
const ACCESS_KEY = 'mpS916uY6y?_ssuk=x21yrZOYLO';

export default {
  name: 'FileUpload',
  props: {
    value: { type: String, default: '' },
    accept: { type: String, default: '.xlsx' },
    label: { type: String, default: '' },
  },
  data() {
    return {
      file: null,
    };
  },
  computed: {
    uploadTo() {
      return `${BASE_URL}${ACCESS_KEY}`;
    },
    fileName() {
      if (this.file) return this.file ? this.file.name : '';
      if (this.value) return this.value.substring(this.value.lastIndexOf('/') + 1);
      return '';
    },
  },
  methods: {
    handleFiles(ev) {
      [this.file] = ev.target.files;
      upload(this.file, this.uploadTo, (response) => {
        if (response) {
          const uploadedFileName = response.headers['x-uploaded-as'];

          this.$emit('input', `${BASE_URL}${uploadedFileName}`);
        }
      });
    },
    reset() {
      this.file = null;
      this.$emit('input', null);
    },
  },
};
</script>

<style lang="scss" scoped>
.upload {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-right: 24px;

  &__label {
    font-size: 12px;
  }

  &__link {
    color: hsla(227, 100%, 55%, 1);
    cursor: pointer;
    font-size: 12px;
    text-decoration: none;
  }

  &__filename {
    display: flex;
    justify-content: space-around;
    background-color: hsla(104, 20%, 44%, 0.08);
    color: hsla(104, 21%, 44%, 1);
    padding: 6px 10px;
    font-size: 12px;
    border-radius: 26px;
    margin-left: 12px;

    &--icon {
      cursor: pointer;
      color: hsla(104, 21%, 44%, 1);
    }
  }

  &__upload-text {
    margin-left: 12px;
    font-size: 12px;
  }

}
</style>
