<template>
  <v-container fluid>
    <JobCreate :operation="activeOperation" :operation-type="activeOperationType" @hide="activeOperation = null" v-if="activeOperation" />
    <v-col cols="12 sticky">
      <v-toolbar dense elevation="0">
        <div class="d-flex align-baseline">
          <div class="operations__title" v-text="'Операции'" />
          <div class="caption text--secondary ml-4" v-text="jobsCount" v-if="jobsCount" />
        </div>
        <v-btn class="operations__btn" color="primary" depressed text @click.native="createJob" v-if="!activeOperation">
          <v-icon>mdi-plus</v-icon>
        </v-btn>

        <OperationsFilters class="d-flex ml-auto" />
      </v-toolbar>
    </v-col>
    <v-col cols="12 pa-0">
      <JobsList :is-loading="isLoading" :has-error="hasError" @repeat="onRepeat" />
    </v-col>
    <v-row class="my-2" justify="center" v-if="pageCount">
      <v-col cols="3">
        <v-pagination v-model="page" :length="pageCount" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';

import PaginationMixin from '@/mixins/PaginationMixin';

import OperationsFilters from '@/components/Operations/OperationsFilters';
import JobCreate from '@/components/Operations/JobCreate';
import JobsList from '@/components/Operations/JobsList';

export default {
  name: 'Operations',
  components: {
    OperationsFilters,
    JobsList,
    JobCreate,
  },
  mixins: [PaginationMixin],
  data() {
    return {
      itemsPerPage: 24,
      activeOperation: null,
      activeOperationType: 'create',
      isLoading: false,
      hasError: false,
      heartBeatId: null,
    };
  },
  metaInfo() {
    return {
      title: 'Массовые операции — Инвентарь',
    };
  },
  computed: {
    ...mapState('operations', ['jobsCount']),
    ...mapGetters('operations', ['jobsIdsToGet']),
    pageCount() {
      return Math.ceil(this.jobsCount / this.itemsPerPage);
    },
  },
  watch: {
    '$route.query': {
      deep: true,
      immediate: false,
      async handler() {
        this.isLoading = true;
        this.hasError = false;

        try {
          await this.FETCH_JOBS(this.$route.query);

          if (this.heartBeatId) clearInterval(this.heartBeatId);
          if (!this.jobsIdsToGet) return;

          this.heartBeatId = setInterval(() => {
            if (navigator.onLine) {
              this.UPDATE_JOBS();
            }
          }, 3000);
        } catch (e) {
          this.hasError = true;
          if (!e.isAxiosError) throw e;
        } finally {
          this.isLoading = false;
        }
      },
    },
  },
  created() {
    this.initialLoad();
  },
  methods: {
    ...mapActions('operations', ['FETCH_JOBS', 'UPDATE_JOBS']),
    ...mapActions('reference', ['FETCH_OWNERS', 'FETCH_REGIONS']),
    async initialLoad() {
      this.isLoading = true;
      this.hasError = false;

      try {
        await Promise.all([
          this.FETCH_OWNERS({ page_size: 100500 }),
          this.FETCH_REGIONS(),
          this.FETCH_JOBS(this.$route.query),
        ]);
      } catch (e) {
        this.hasError = true;
        if (!e.isAxiosError) throw e;
      } finally {
        this.isLoading = false;
      }
    },
    createJob() {
      this.activeOperation = null;
      this.activeOperation = { operation_slug: null, data_url: '', extra_parameters: null };
      this.activeOperationType = 'create';
    },
    onRepeat(val) {
      this.activeOperation = val;
      this.activeOperationType = 'repeat';
      this.$scrollTo('body');
    },
  },
};
</script>

<style lang="scss" scoped>
.sticky {
  padding-bottom: 16px;
  padding-left: 0;
  position: sticky;
  top: 0;
  background-color: hsl(0, 0%, 100%);
  border-bottom: 1px solid hsla(0, 0%, 92%, 1);
  z-index: 5;
}

.operations {
  &__title {
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
  }

  &__btn {
    border-radius: 6px;
    background-color: rgba(28, 78, 255, 0.08);
    font-weight: 400;
    margin-left: 24px;
  }
}
</style>
