<template>
  <svg width="100%" height="4" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g>
      <rect y="0.000106812" width="100%" height="4" rx="2" fill="#E5E5E5" />
      <rect y="0.000106812" :width="percent" height="4" rx="2" fill="#00CC6A" />
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    total: { type: Number, default: 100 },
    processed: { type: Number, default: 0 },
  },
  computed: {
    percent() {
      return `${parseInt((this.processed * 100) / this.total, 10)}%`;
    },
  },
};
</script>

<style>

</style>
