<template>
  <div>
    <div class="py-16 text-center" v-if="isLoading">
      <v-progress-circular size="42" indeterminate />
    </div>
    <v-col cols="12" class="my-15 text-center" v-else-if="hasError">
      Что-то пошло не так, попробуйте обновить страницу или <router-link to="/">перейти на главную</router-link>
    </v-col>
    <div class="py-16 text-center" v-else-if="!jobs.length">
      Здесь ничего нет
    </div>
    <v-simple-table class="brands-list" v-else>
      <template v-slot:default>
        <thead>
          <tr class="brands-list__header">
            <th class="text-left" v-text="'#'" width="2%" />
            <th class="text-left" v-text="'Название операции'" width="20%" />
            <th class="text-left" v-text="'Инициатор'" width="15%" />
            <th class="text-left" v-text="'Начало'" width="15%" />
            <th class="text-left" v-text="'Завершение'" width="15%" />
            <th class="text-left" v-text="'Статус'" width="5%" />
            <th class="text-left" v-text="'Обработано'" />
          </tr>
        </thead>
        <tbody>
          <JobsListRow v-for="item in jobs" :key="item.id" :item="item" @repeat="onRepeat" />
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import JobsListRow from '@/components/Operations/JobsListRow';

export default {
  name: 'JobsList',
  components: {
    JobsListRow,
  },
  props: {
    isLoading: { type: Boolean, default: false },
    hasError: { type: Boolean, default: false },
  },
  computed: {
    ...mapState('operations', ['jobs']),
  },
  methods: {
    onRepeat(val) {
      this.$emit('repeat', val);
    },
  },
};
</script>
