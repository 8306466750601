<template>
  <div>
    <div class="job-create__title" v-text="operationTitle" />
    <div class="job-create__controls d-flex flex-wrap">
      <v-select class="job-create__controls--operations field mr-4 mb-2" v-model="selectedOperation" label="Массовая операция" :items="options" item-text="label" item-value="value" outlined dense hide-details />
      <div class="job-create__load-feed mb-2" v-if="selectedOperation === 'load_feed'">
        <v-autocomplete class="field mr-4" v-model="extra.region_slug" label="Регион" :items="regions" item-text="name" item-value="slug" outlined dense hide-details />
        <v-autocomplete class="field mr-4" v-model="extra.owner_slug" label="Источник" :items="ownersForSelect" item-text="text" item-value="value" outlined dense hide-details />
      </div>
      <FileUpload class="mb-2" v-model="dataURL" label="Данные:" />
      <div>
        <AppButton class="mb-2" theme="primary" text="Выполнить операцию" :disabled="appButtonDisabled" @click.native="createJob" />
        <AppButton class="mb-2 ml-4" theme="danger" text="Отменить" @click.native="$emit('hide')" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import { OPERATIONS } from '@/helpers/operations';
import FileUpload from '@/components/common/FileUpload';
import AppButton from '@/components/AppButton';

const OPERATION_TITLE = {
  create: 'Новая операция',
  repeat: 'Повторить операцию',
};

export default {
  name: 'JobCreate',
  components: {
    FileUpload,
    AppButton,
  },
  props: {
    operation: { type: Object, required: false, default: () => ({ operation_slug: null, data_url: '', extra_parameters: null }) },
    operationType: { type: String, required: true, default: 'create', validator: (value) => ['create', 'repeat'].indexOf(value) !== -1 },
  },
  data() {
    return {
      selectedOperation: null,
      dataURL: '',
      extra: null,
    };
  },
  computed: {
    ...mapState('reference', ['regions']),
    ...mapGetters('reference', ['ownersForSelect']),
    options() {
      return Object.entries(OPERATIONS).map(([key, value]) => ({ label: value, value: key }));
    },
    jobObject() {
      const jobObject = {
        operation_slug: this.selectedOperation,
        data_url: this.dataURL,
      };
      if (this.extra) jobObject.extra_parameters = this.extra;
      return jobObject;
    },
    extraIsReady() {
      if (this.selectedOperation === 'load_feed') {
        return (this.extra && this.extra.region_slug && this.extra.owner_slug);
      }
      return true;
    },
    appButtonDisabled() {
      return !this.selectedOperation || !this.dataURL || !this.extraIsReady;
    },
    operationTitle() {
      return OPERATION_TITLE[this.operationType] ?? OPERATION_TITLE.create;
    },
  },
  watch: {
    operation: {
      immediate: true,
      handler() {
        if (this.operation.operation_slug) this.selectedOperation = this.operation.operation_slug;
        if (this.operation.data_url) this.dataURL = this.operation.data_url;
        if (this.operation.extra_parameters) this.extra = this.operation.extra_parameters;
      },
    },
    selectedOperation() {
      if (this.selectedOperation === 'load_feed' && !this.extra) this.extra = { region_slug: null, owner_slug: null };
    },
  },
  methods: {
    ...mapActions('operations', ['CREATE_JOB']),
    async createJob() {
      try {
        this.getActiveJob = this.jobObject;
        await this.CREATE_JOB(this.jobObject);
        this.$emit('hide');
        this.$notify.showMessage({ message: 'Задача добавлена', color: 'success' });
      } catch (e) {
        if (!e.isAxiosError) throw e;
        this.$notify.showErrorMessage(e.response);
      }
    },
  },
};
</script>

<style lang=scss scoped>
.field {
  font-size: 12px;
  max-width: 280px;
  margin-right: 24px;
}

::v-deep .v-label {
  font-size: 12px;
}

.job-create {
  &__title {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 24px;
  }

  &__load-feed {
    display: flex;
    align-items: center;
  }

  &__controls {

    &--operations {
      min-width: 280px;
      max-width: 380px;
    }
  }
}
</style>
