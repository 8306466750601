<template>
  <div class="operations-filters">
    <QueryFilterObserver v-bind="filters.operationSlug" />
    <QueryFilterObserver v-bind="filters.region" />
    <QueryFilterObserver v-bind="filters.owner" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import { OPERATIONS } from '@/helpers/operations';

import QueryFilterObserver from '@/components/QueryFilterObserver';

export default {
  components: {
    QueryFilterObserver,
  },
  computed: {
    ...mapGetters('reference', ['regionsForSelect', 'ownersForSelect']),
    operationsOptions() {
      return Object.entries(OPERATIONS).map(([key, value]) => ({
        text: value,
        value: key,
      }));
    },
    filters() {
      return {
        operationSlug: {
          component: 'v-select',
          name: 'operation_slug',
          attrs: {
            class: 'operations-filters__field',
            label: 'Операция',
            items: this.operationsOptions,
            clearable: true,
          },
        },
        region: {
          component: 'v-select',
          name: 'region',
          attrs: {
            class: 'operations-filters__field',
            label: 'Регион',
            items: this.regionsForSelect,
            clearable: true,
          },
        },
        owner: {
          component: 'v-select',
          name: 'owner',
          attrs: {
            class: 'operations-filters__field',
            label: 'Поставщик',
            items: this.ownersForSelect,
            clearable: true,
          },
        },
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.operations-filters {
  display: flex;
  gap: 12px;

  &__field {
    width: 200px;
  }
}
</style>
